<template>
  <b-dropdown
    ref="dropdown"
    :menu-class="menuClasses"
    :popper-opts="{ placement: 'top', modifiers: { offset: { offset: '0,0' } } }"
    right
    variant="outline-reduced-dark"
    boundary="scrollParent"
  >
    <template #button-content>
      <i v-if="hasSelected" class="fal fa-filter mr-1" />
      {{ label }}
      <span class="caret" />
    </template>
    <b-dropdown-form>
      <b-input-group v-if="showSearch" class="mb-3" style="min-width: 160px">
        <b-input-group-prepend is-text>
          <i class="fal fa-search" />
        </b-input-group-prepend>
        <b-form-input id="document-search" v-model="searchString" :placeholder="$gettext('Search')" debounce="500" />
      </b-input-group>
      <b-form-checkbox-group
        v-model="internalSelected"
        stacked
        class="table-filter-dropdown"
        :options="filteredOptions"
      />
      <b-button v-translate class="mt-2" variant="primary" block @click="applyFilters">Apply</b-button>
    </b-dropdown-form>
  </b-dropdown>
</template>

<script lang="ts">
import { BDropdown } from 'bootstrap-vue'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

import { IFilterOption } from '@/types/filters'

@Component({
  name: 'table-filter-dropdown',
})
export default class TableFilterDropdown extends Vue {
  @Prop() label!: string
  @Prop() filterName!: string
  @Prop() fieldName!: string
  @Prop({ default: () => [] }) options!: IFilterOption
  @Prop({ default: () => [] }) selected!: string[]
  @Prop({ default: '' }) menuClass!: string
  @Prop({ default: false }) searchable!: boolean
  $refs!: { dropdown: BDropdown }
  internalSelected: string[] = []
  searchString = ''

  @Watch('selected')
  onSelectedChanged(value: string[]): void {
    // Only update if those two diverge, or we will end up in an infinite loop
    if (
      !(
        value.length === this.internalSelected.length &&
        this.internalSelected.every((selectValue, index) => selectValue === value[index])
      )
    ) {
      this.internalSelected = [...this.selected]
    }
  }

  @Watch('internalSelected')
  onInternalSelectedChanged(value: string[]): void {
    this.$emit('update:selected', value)
  }

  get filteredOptions(): IFilterOption {
    if (this.searchString) {
      // eslint-disable-next-line unicorn/no-array-reduce
      return Object.keys(this.options).reduce((newObject, key) => {
        if (this.options[key].toLowerCase().includes(this.searchString.toLowerCase())) {
          newObject[key] = this.options[key]
        }
        return newObject
      }, {})
    }
    return this.options
  }

  get showSearch(): boolean {
    return this.searchable || Object.keys(this.options).length >= 15
  }

  get hasSelected(): boolean {
    return this.internalSelected.length > 0
  }

  get menuClasses(): string {
    return `dropdown-menu-search ${this.menuClass}`
  }

  applyFilters(): void {
    this.$refs.dropdown.hide(true)
    this.$emit('on-apply', { filterName: this.filterName, selected: this.selected, fieldName: this.fieldName })
  }

  mounted(): void {
    this.internalSelected = [...this.selected]
  }
}
</script>

<style scoped scss>
.table-filter-dropdown {
  max-height: 250px;
  max-width: 50vw;
  overflow-x: auto;
  overflow-y: auto;
}
</style>
