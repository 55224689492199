<template>
  <div>
    <b-modal id="reminder-modal" v-model="isOpened" centered size="xl" v-on="$listeners" @show="fetchReminderDetails">
      <template #modal-title>
        <p v-translate class="modal-header-sm">Tasks</p>
        <p v-if="organization" key="organization-title" v-translate class="modal-header-lg">
          Organization: %{ organization.title }
        </p>
        <p v-else key="project-title" v-translate class="modal-header-lg">Project: %{ project.title }</p>
      </template>

      <template #default>
        <v-wait for="fetch projects">
          <template slot="waiting">
            <div class="row">
              <div class="col-12 justify-content-center" style="margin-top: 64px">
                <b-spinner variant="primary" label="Spinning" />
              </div>
            </div>
          </template>
          <p v-translate>
            Create tasks for yourself and other team members to be completed at a particular time. The responsible
            person selected below will receive an e-mail after the task has been created and on the day the task is due.
          </p>

          <div class="row">
            <div class="col-md-7">
              <ReminderDetails
                v-if="selectedReminder && !editActive"
                :reminder="selectedReminder"
                @close-details="closeDetails"
              />
              <ReminderForm
                v-else
                :project="project"
                :organization="organization"
                :reminder="selectedReminder"
                :reminderTemplate="selectedReminderTemplate"
                @cancel-edit="cancelEdit"
                @refresh-reminders="fetchReminderDetails"
              />
            </div>
            <div class="col-md-5">
              <div class="form-module py-4">
                <fieldset>
                  <label v-translate class="control-label" for="reminder_template_select"> Choose a template </label>
                  <b-form-select
                    id="reminder_template_select"
                    v-model="selectedReminderTemplateId"
                    :options="reminderTemplateOptions"
                  />
                </fieldset>
              </div>
            </div>
          </div>

          <!-- TODO: Add info about permissions. Like it is done in the old project view.
          Why not now? Because I want to reuse the bs4 info boxes and the next step in my refactoring are the Vue components -->

          <div class="row">
            <div class="col-sm-12">
              <h4 v-translate>Already scheduled tasks</h4>
              <b-table
                hover
                borderless
                striped
                outlined
                responsive
                show-empty
                :empty-text="$gettext('No tasks set yet')"
                :fields="fields"
                :items="reminders"
                :busy="tableLoading"
              >
                <template #table-busy>
                  <div class="text-center my-2">
                    <b-spinner variant="primary" class="align-middle" />
                  </div>
                </template>

                <template #cell(title)="data">
                  {{ data.item.title }}
                  <div class="options" style="margin-top: -0.9rem">
                    <span
                      v-if="data.item.can_resolve"
                      id="resolve-reminder"
                      class="badge badge-pill badge-hover"
                      @click="resolveReminder(data.item)"
                    >
                      <i class="fal fa-check mr-1" />
                      <translate>Done</translate>
                    </span>
                    <b-dropdown
                      size="lg"
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                      text="Dropdown Button"
                    >
                      <template #button-content>
                        <span class="badge badge-pill badge-hover">
                          <i class="fal fa-ellipsis-v mr-1" />
                          <translate>Options</translate>
                        </span>
                      </template>
                      <b-dropdown-item-button v-if="data.item.can_resolve" @click="resolveReminder(data.item)">
                        <i class="fal fa-check mr-1" />
                        <translate>Done</translate>
                      </b-dropdown-item-button>
                      <b-dropdown-item-button
                        v-if="data.item.can_edit"
                        id="select-reminder-edit"
                        @click="selectReminder(data.item, true)"
                      >
                        <i class="fal fa-pencil mr-1" />
                        <translate>Edit</translate>
                      </b-dropdown-item-button>
                      <b-dropdown-item-button id="select-reminder-detail" @click="selectReminder(data.item, false)">
                        <i class="fal fa-eye" />
                        <translate>View details</translate>
                      </b-dropdown-item-button>
                      <b-dropdown-item-button
                        v-if="data.item.can_delete"
                        id="open-delete-reminder"
                        @click="openDeleteModal(data.item)"
                      >
                        <i class="fal fa-trash mr-1" />
                        <translate>Delete</translate>
                      </b-dropdown-item-button>
                    </b-dropdown>
                  </div>
                </template>

                <template #cell(status)="data">
                  <ReminderStatus :reminder="data.item" />
                </template>
              </b-table>
            </div>
          </div>
        </v-wait>
      </template>

      <template #modal-footer="{ close }">
        <b-button id="close-delete-modal-btn" variant="default" class="mr-1" @click="close()">
          <translate>Close</translate>
        </b-button>
      </template>
    </b-modal>

    <DeleteModal
      v-model="deleteModalOpen"
      :title="selectedReminder?.title ?? '-'"
      @confirm-delete="confirmDelete(selectedReminder)"
    />
  </div>
</template>

<script lang="ts">
import axios from 'axios'
import { BvTableFieldArray } from 'bootstrap-vue'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'

import DeleteModal from '@/components/modals/DeleteModal.vue'
import ReminderDetails from '@/components/reminders/ReminderDetails.vue'
import ReminderForm from '@/components/reminders/ReminderForm.vue'
import ReminderStatus from '@/components/reminders/ReminderStatus.vue'
import LocaleMixin from '@/mixins/LocaleMixin'
import { TGenericObject } from '@/types/base'
import { IOrganization, IProject } from '@/types/projects'
import { IReminder, IReminderTemplate } from '@/types/reminders'
import { IUser } from '@/types/users'
import { API_URLS } from '@/utils/helpers'

@Component({
  components: {
    ReminderForm,
    ReminderStatus,
    ReminderDetails,
    DeleteModal,
  },
  mixins: [LocaleMixin],
})
export default class ReminderModal extends Mixins(LocaleMixin) {
  @Prop({ default: false }) value!: boolean
  @Prop({ default: null }) project!: IProject | null
  @Prop({ default: null }) organization!: IOrganization | null

  reminders: IReminder[] = []
  selectedReminder: IReminder = null
  reminderTemplates: IReminderTemplate[] = []
  selectedReminderTemplate: IReminderTemplate = null
  selectedReminderTemplateId = 0
  editActive = false
  tableLoading = false
  deleteModalOpen = false

  fields: BvTableFieldArray = [
    'status',
    { key: 'title', label: this.$gettext('Task') },
    {
      key: 'scheduled_for',
      label: this.$gettext('Due date'),
      formatter: (date) => this.formatDate(date, 'DD.MM.YYYY'),
    },
    {
      key: 'assignees',
      label: this.$gettext('Assignees'),
      formatter: (x: undefined, y: string, reminder: IReminder) =>
        reminder.users.map((user) => user.full_name_w_username).join(', '),
    },
    {
      key: 'created_by',
      label: this.$gettext('Created by'),
      formatter: (createdBy: IUser) => createdBy.full_name_w_username,
    },
  ]

  @Watch('selectedReminderTemplateId')
  onSelectedReminderTemplateIdChange(): void {
    if (!this.selectedReminderTemplateId) {
      this.selectedReminderTemplate = null
    }

    const filteredTemplates = this.reminderTemplates.filter(
      (template) => template.id === this.selectedReminderTemplateId
    )

    if (filteredTemplates.length === 1) {
      this.selectedReminderTemplate = filteredTemplates[0]
    }
  }

  get isOpened(): boolean {
    return this.value
  }

  set isOpened(value: boolean) {
    this.$emit('input', value)
  }

  openDeleteModal(reminder: IReminder): void {
    this.deleteModalOpen = true
    this.selectedReminder = reminder
  }

  async confirmDelete(reminder: IReminder): Promise<void> {
    axios.delete(API_URLS.REMINDERS.RETRIEVE(reminder.slug)).then(() => {
      this.deleteModalOpen = false
      return this.fetchReminderDetails()
    })
  }

  async resolveReminder(reminder: IReminder): Promise<void> {
    await axios.post(API_URLS.REMINDERS.RESOLVE(reminder.slug), {
      resolve: true,
    })
    this.fetchReminderDetails()
  }

  selectReminder(reminder: IReminder, edit = true): void {
    this.selectedReminder = reminder
    this.editActive = edit
  }

  cancelEdit(): void {
    this.selectedReminder = null
    this.editActive = false
  }

  closeDetails(): void {
    this.selectedReminder = null
    this.editActive = false
  }

  // does not gets called anywhere in the component
  /* get locale (): string {
    if (this.$language.current) {
      return this.$language.current.startsWith('de_') ? 'de' : this.$language.current.replace('_', '-')
    }
    return 'en-US'
  } */

  get reminderTemplateOptions(): TGenericObject[] {
    const defaultOptions: TGenericObject[] = [{ value: 0, text: '----------' }]

    const additionalOptions = this.reminderTemplates.map((reminderTemplate) => {
      return {
        value: reminderTemplate.id,
        text: reminderTemplate.title,
      }
    })

    return [...defaultOptions, ...additionalOptions]
  }

  get reminderDetailsUrl(): string {
    if (!(this.organization || this.project)) {
      return null
    }

    return this.project
      ? API_URLS.REMINDERS.PROJECT_LIST(this.project.id)
      : API_URLS.REMINDERS.ORGANIZATION_LIST(this.organization.id)
  }

  async fetchReminderDetails(): Promise<void> {
    this.selectedReminderTemplateId = 0

    if (!this.reminderDetailsUrl) {
      return
    }

    this.tableLoading = true

    await axios.get(this.reminderDetailsUrl).then((response) => {
      this.reminders = response.data.results
    })

    this.tableLoading = false
  }

  async fetchReminderTemplates(): Promise<void> {
    axios.get(API_URLS.REMINDERS.TEMPLATES.LIST).then((response) => {
      this.reminderTemplates = response.data.results
    })
  }

  async created(): Promise<void> {
    this.fetchReminderDetails()
    this.fetchReminderTemplates()
  }
}
</script>

<style scoped>
.modal-header-sm {
  font-size: 15px;
  line-height: 18px;
  color: #777;
}
.modal-header-lg {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
}
</style>
